<template>
  <a-modal v-model="showPopup" :width='800' :title="titleText" @cancel="handleCancel" @ok="handleConfirm">
    <a-form-model ref="ruleForm" :model="params" v-bind="layout">
<!--   百科标题输入   -->
      <a-form-item label="标题">
        <a-input allowClear v-model="params.title" class="w-200" placeholder='标题' />
      </a-form-item>
<!--   一级分类   -->
      <a-form-item label="一级分类名称">
        <a-select
            allowClear
            showSearch
            :filter-option="untils.filterOption"
            class="ml-5 w-200 mb-5"
            v-model="wikiCategoryId"
            placeholder="一级大分类搜索"
            @change="handleChangeTopCategory($event)"
        >
          <a-select-option v-for="item of topCategoryTypeList" :key="item.id" :value="item.id">{{ item.categoryName }}</a-select-option>
        </a-select>
      </a-form-item>
<!--   二级分类名称搜索   -->
      <a-form-item label="二级分类名称" v-if="wikiCategoryId">
        <a-select
            allowClear
            showSearch
            :filter-option="untils.filterOption"
            class="w-200"
            v-model="params.topicId"
            placeholder="二级分类名称"
            @change="handleChangeTopicType($event)"
        ><a-select-option v-for="item of leftCategoryTypeList" :key="item.id" :value="item.id">{{ item.topicName }}</a-select-option></a-select>
      </a-form-item>
<!--   三级分类   -->
      <a-form-item label="三级分类名称" v-if="wikiCategoryId === 4">
        <a-select
            allowClear
            showSearch
            :filter-option="untils.filterOption"
            class="w-200"
            v-model="params.groupId"
            placeholder="三级分类名称"
        ><a-select-option v-for="item of huaSmallCategoryList" :key="item.id" :value="item.id">{{ item.groupName }}</a-select-option></a-select>
        <a-button class="ml-20" type="danger" @click="handleGoToAddHuaSmallCategory">添加花钱三级分类</a-button>
      </a-form-item>
<!--   封面图上传   -->
      <a-form-item label="上传封面图">
        <a-upload
            key="upload-cover"
            :action="ip + '/file/upload'"
            list-type="picture-card"
            :file-list="coverImgList"
            @preview="handleUploadShowBigImg($event)"
            @change="handleUploadCoverImg"
        ><div v-if="coverImgList.length < 1"><a-icon type="plus"/><div class="ant-upload-text">上传封面图</div></div></a-upload>
      </a-form-item>
<!--   正面图，反面图上传   -->
      <a-form-item label="上传正/反面图">
        <div class="flex-start-center sp-upload-box">
          <a-upload
              key="upload-front"
              :action="ip + '/file/upload'"
              list-type="picture-card"
              :file-list="frontImgList"
              @preview="handleUploadShowBigImg($event)"
              @change="handleUploadFrontImg"
          ><div v-if="frontImgList.length < 1"><a-icon type="plus"/><div class="ant-upload-text">上传正面图</div></div></a-upload>
          <a-upload
              key="upload-back"
              :action="ip + '/file/upload'"
              list-type="picture-card"
              :file-list="backImgList"
              @preview="handleUploadShowBigImg($event)"
              @change="handleUploadBackImg"
          ><div v-if="backImgList.length < 1"><a-icon type="plus"/><div class="ant-upload-text">上传反面图</div></div></a-upload>
        </div>
      </a-form-item>
<!--   百科排序   -->
      <a-form-item label="百科排序">
        <a-input allowClear v-model="params.sort" type="number" class="w-200" placeholder='百科排序'></a-input>
      </a-form-item>
<!--   关联币种   -->
      <a-form-item label="关联币种" v-if="isEditor">
        <a-tag
            v-for="item in selectedCoinList"
            color="blue"
            closable
            :key="item.coinId"
            @close="handleDeleteTag($event, item)"
        >{{ item.coinName + '--' + item.coinCategoryName }}</a-tag>
      </a-form-item>
<!--   朝代   -->
      <a-form-item label="朝代">
        <a-select
            @search="handleSearchDynastyByName"
            v-model="params.dynastyId"
            :filter-option="untils.filterOption"
            showSearch
            allowClear
            placeholder="请选择"
        >
          <a-select-option
              v-for="(item, index) of dynastyList"
              :key="index"
              :value="item.id"
          >{{ item.dynastyName }}</a-select-option>
        </a-select>
      </a-form-item>
<!--   搜索大币种   -->
      <a-form-item label="关联大币种">
        <a-select
            @search="coinSearch"
            v-model="coinIds"
            :filter-option="untils.filterOption"
            showSearch
            mode="multiple"
            placeholder="请选择"
        >
          <a-select-option
              v-for="(item, index) of bigCoinList"
              :key="index"
              :value="item.id"
          >{{ item.coinKindName + (item.coinCategory ? `---${item.coinCategory}` : '' ) }}</a-select-option>
        </a-select>
      </a-form-item>
<!--   百科是否可以直接上线   -->
      <a-form-item label="是否直接可使用">
        <a-radio-group name="radioGroup" :default-value="0" v-model="params.status">
          <a-radio :value="0">不可用</a-radio>
          <a-radio :value="1">可用</a-radio>
        </a-radio-group>
      </a-form-item>
<!--   百科内容说明   -->
      <a-form-item label="百科内容说明" v-if="showPopup">
        <a-input
            id="inputKeywordTabDesc"
            v-model="params.itemDesc"
            class="w-100-w"
            type="textarea"
            :auto-size="{ minRows: 6, maxRows: 20 }"
            placeholder="百科内容"
        />
      </a-form-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import {goToOtherPage, updateBaseObjectWithComplex} from "@/untils/otherEvent"
import {
  getHuaSmallCategoryListByDetailApi,
  postAddCoinWikiCategoryDetailApi,
  postEditorCoinWikiCategoryDetailApi
} from "@/views/cmsPage/currencyMannage/coinWikiCategoryDetailManage/_apis"
import _searchLeftCategoryListMixin
  from "@/views/cmsPage/currencyMannage/coinWikiCategoryDetailManage/_mixins/_searchLeftCategoryListMixin"
import untils from "@/untils"
import {mapState} from "vuex"
import _editorCategoryDetailMixin
  from "@/views/cmsPage/currencyMannage/coinWikiCategoryDetailManage/_mixins/_editorCategoryDetailMixin"
import _showUploadBigImgMixin from "@/_mixins/_showUploadBigImgMixin"
import _bigOrSmallCoinByVersion from "@/_mixins/_bigOrSmallCoinByVersion"
import {getDynastyListByName} from "@/axios/apis"
import _inputKeywordTabImxin from "../../../../../../_mixins/_inputKeywordTabMixin"

export default {
  components: {
  },
  emits: ['success'],
  props: ['topCategoryTypeList'],
  mixins: [
      _searchLeftCategoryListMixin, _editorCategoryDetailMixin, _showUploadBigImgMixin,
      _bigOrSmallCoinByVersion, _inputKeywordTabImxin
  ],
  computed: {
    ...mapState(['ip'])
  },
  data() {
    return {
      untils,
      showPopup: false,
      titleText: '新增百科数据',
      coverImgList: [], // 封面图
      frontImgList: [], // 正面图
      backImgList: [], // 反面图
      leftCategoryTypeList: [],
      layout: {
        labelCol: { span: 4 },
        wrapperCol: { span: 20 }
      },
      params: {
        id: undefined,
        topicId: undefined,
        title: undefined,
        itemDesc: '',
        groupId: undefined, // 三级分类ID
        frontUrl: undefined,
        backUrl: undefined,
        coverUrl: undefined,
        sort: undefined,
        status: undefined,
        coinIdsList: undefined,
        dynastyId: undefined,
      },
      selectedCoinList: [], // 编辑时传递的已经关联的大币种
      coinIds: [], // 新增关联的大币种
      bigCoinList: [],
      dynastyList: [], // 朝代列表
      wikiCategoryId: undefined, // 一级分类
      huaSmallCategoryList: [], // 三级分类列表（花钱）
      options: { // 文本编辑器配置
        toolbarKeys :[
          'color', 'fontSize', 'fontFamily', 'lineHeight', 'indent', 'delIndent',
        ]
      },
      option: {
        height: 300
      },
      isEditor: false,
      inputBoxEl: null
    }
  },
  mounted() {},
  methods: {
    /** 添加花钱三级分类 */
    handleGoToAddHuaSmallCategory() {
      this.showPopup = false
      goToOtherPage(`/cms/coinwiki/classify?topCategoryId=${4}`)
    },
    /** 删除已经选择的 */
    handleDeleteTag(e, item) {
      e.preventDefault();
      const foundIndex = this.selectedCoinList.findIndex(el => el.coinId === item.coinKindId)
      this.selectedCoinList.splice(foundIndex, 1)
    },
    async show(data) {
      this.$loading.show()
      if (data) {
        this.isEditor = true
        this.titleText = '编辑百科数据'
        this.wikiCategoryId = data.wikiCategoryId
        await this.handleChangeTopCategory(data.wikiCategoryId)
        if (this.wikiCategoryId === 4) {
          await this.handleChangeTopicType(data.topicId)
        }
        await this.handleSearchDynastyByName(data.dynastyName)
        this.selectedCoinList = JSON.parse(JSON.stringify(data.coinList))
        this.handleReturnShowEditorData(data)
        this.params = updateBaseObjectWithComplex(this.params, data)
      } else {
        this.isEditor = false
      }
      this.$loading.hide()
      this.showPopup = true
      setTimeout(() => {
        this.addTabListeners()
      }, 500)
    },
    /** 确定修改 */
    async handleConfirm() {
      if (!this.params.title) return this.$message.warning('请请输百科标题')
      if (!this.params.topicId) return this.$message.warning('二级分类必须选择')
      if (this.wikiCategoryId === 4 && !this.params.groupId) return this.$message.warning('花钱三级分类必须选择')
      if (this.params.status && !this.coinIds && this.selectedCoinList.length < 1) return this.$message.warn('关联大币种不能为空')
      if (this.isEditor) {
        const tempArr = this.selectedCoinList.map(el => el.coinId) || []
        const tempArrCoin = this.coinIds || []
        this.params.coinIdsList = tempArrCoin.concat(tempArr)
      } else {
        this.params.coinIdsList = this.coinIds
      }
      if (this.coverImgList.length) this.params.coverUrl = this.coverImgList[0].url
      if (this.frontImgList.length) this.params.frontUrl = this.frontImgList[0].url
      if (this.backImgList.length) this.params.backUrl = this.backImgList[0].url
      this.$loading.show()
      let res
      if (this.isEditor) {
        res = await postEditorCoinWikiCategoryDetailApi(this.params)
      } else {
        res = await postAddCoinWikiCategoryDetailApi(this.params)
      }
      this.$loading.hide()
      if (res.status !== '200') return;
      this.$message.info('修改成功')
      this.$emit('success')
      this.handleCancel()
    },
    /** 取消 */
    handleCancel() {
      this.params = {
        id: undefined,
        topicId: undefined,
        title: undefined,
        groupId: undefined, // 三级分类ID
        itemDesc: '',
        frontUrl: undefined,
        backUrl: undefined,
        coverUrl: undefined,
        sort: undefined,
        status: undefined,
        coinIdsList: undefined,
        dynastyId: undefined,
      }
      this.selectedCoinList = [] // 编辑时传递的已经关联的大币种
      this.coinIds = [] // 新增关联的大币种
      this.bigCoinList = []
      this.huaSmallCategoryList = [] // 三级分类列表（花钱）
      this.coverImgList = [] // 封面图
      this.frontImgList = [] // 正面图
      this.backImgList = [] // 反面图
      this.showPopup = false
    },
    /** 切换顶部一级分类 */
    async handleChangeTopCategory(id) {
      if (id) {
        await this.handleSearchLeftCategory('', id)
      } else {
        this.leftCategoryTypeList = []
      }
    },
    /** 切换耳二级分类-搜索三级花钱小分类 */
    async handleChangeTopicType(id) {
      if (this.wikiCategoryId === 4) {
        const res = await getHuaSmallCategoryListByDetailApi(id)
        if (res.status !== '200') return
        this.huaSmallCategoryList = res.data.records
      }
    },
    /** 搜索朝代 */
    async handleSearchDynastyByName(str) {
      const res = await getDynastyListByName(str)
      if (res.status !== '200') return
      this.dynastyList = res.data
    }
  },
}
</script>
<style lang="scss" scoped>
.sp-upload-box::v-deep .ant-upload-picture-card-wrapper {
  width: 25%;
}
</style>