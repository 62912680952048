
/** 订单列表 */
export const coinWikiCategoryDetailTableColumns = [
    { title: "百科标题", align: "center", scopedSlots: {customRender: 'itemWikiTitleSlot'}, width: 200 },
    { title: "封面图", align: "center", scopedSlots: {customRender: 'itemWikiCoverImgSlot'}, width: 80 },
    { title: "正反面", align: "center", scopedSlots: {customRender: 'itemWikiFrontAndBackImgSlot'}, width: 100 },
    { title: "排序", align: "center", dataIndex: 'sort', width: 100 },
    { title: "关联币种", align: "center", scopedSlots: {customRender: 'itemWikiAssociationCoinSlot'}, width: 500 },
    { title: "创建时间", align: "center", dataIndex: 'createTime', width: 120 },
    { title: "更新时间", align: "center", dataIndex: 'updateTime', width: 120 },
    { title: "是否可用", align: "center", scopedSlots: {customRender: 'itemStatusSlot' }, width: 150 },
    { title: "操作相关", align: "center", scopedSlots: {customRender: 'itemActionSlot'}, width: 200 }
]