var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { width: 800, title: _vm.titleText },
      on: { cancel: _vm.handleCancel, ok: _vm.handleConfirm },
      model: {
        value: _vm.showPopup,
        callback: function($$v) {
          _vm.showPopup = $$v
        },
        expression: "showPopup"
      }
    },
    [
      _c(
        "a-form-model",
        _vm._b(
          { ref: "ruleForm", attrs: { model: _vm.params } },
          "a-form-model",
          _vm.layout,
          false
        ),
        [
          _c(
            "a-form-item",
            { attrs: { label: "标题" } },
            [
              _c("a-input", {
                staticClass: "w-200",
                attrs: { allowClear: "", placeholder: "标题" },
                model: {
                  value: _vm.params.title,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "title", $$v)
                  },
                  expression: "params.title"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "一级分类名称" } },
            [
              _c(
                "a-select",
                {
                  staticClass: "ml-5 w-200 mb-5",
                  attrs: {
                    allowClear: "",
                    showSearch: "",
                    "filter-option": _vm.untils.filterOption,
                    placeholder: "一级大分类搜索"
                  },
                  on: {
                    change: function($event) {
                      return _vm.handleChangeTopCategory($event)
                    }
                  },
                  model: {
                    value: _vm.wikiCategoryId,
                    callback: function($$v) {
                      _vm.wikiCategoryId = $$v
                    },
                    expression: "wikiCategoryId"
                  }
                },
                _vm._l(_vm.topCategoryTypeList, function(item) {
                  return _c(
                    "a-select-option",
                    { key: item.id, attrs: { value: item.id } },
                    [_vm._v(_vm._s(item.categoryName))]
                  )
                }),
                1
              )
            ],
            1
          ),
          _vm.wikiCategoryId
            ? _c(
                "a-form-item",
                { attrs: { label: "二级分类名称" } },
                [
                  _c(
                    "a-select",
                    {
                      staticClass: "w-200",
                      attrs: {
                        allowClear: "",
                        showSearch: "",
                        "filter-option": _vm.untils.filterOption,
                        placeholder: "二级分类名称"
                      },
                      on: {
                        change: function($event) {
                          return _vm.handleChangeTopicType($event)
                        }
                      },
                      model: {
                        value: _vm.params.topicId,
                        callback: function($$v) {
                          _vm.$set(_vm.params, "topicId", $$v)
                        },
                        expression: "params.topicId"
                      }
                    },
                    _vm._l(_vm.leftCategoryTypeList, function(item) {
                      return _c(
                        "a-select-option",
                        { key: item.id, attrs: { value: item.id } },
                        [_vm._v(_vm._s(item.topicName))]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.wikiCategoryId === 4
            ? _c(
                "a-form-item",
                { attrs: { label: "三级分类名称" } },
                [
                  _c(
                    "a-select",
                    {
                      staticClass: "w-200",
                      attrs: {
                        allowClear: "",
                        showSearch: "",
                        "filter-option": _vm.untils.filterOption,
                        placeholder: "三级分类名称"
                      },
                      model: {
                        value: _vm.params.groupId,
                        callback: function($$v) {
                          _vm.$set(_vm.params, "groupId", $$v)
                        },
                        expression: "params.groupId"
                      }
                    },
                    _vm._l(_vm.huaSmallCategoryList, function(item) {
                      return _c(
                        "a-select-option",
                        { key: item.id, attrs: { value: item.id } },
                        [_vm._v(_vm._s(item.groupName))]
                      )
                    }),
                    1
                  ),
                  _c(
                    "a-button",
                    {
                      staticClass: "ml-20",
                      attrs: { type: "danger" },
                      on: { click: _vm.handleGoToAddHuaSmallCategory }
                    },
                    [_vm._v("添加花钱三级分类")]
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-form-item",
            { attrs: { label: "上传封面图" } },
            [
              _c(
                "a-upload",
                {
                  key: "upload-cover",
                  attrs: {
                    action: _vm.ip + "/file/upload",
                    "list-type": "picture-card",
                    "file-list": _vm.coverImgList
                  },
                  on: {
                    preview: function($event) {
                      return _vm.handleUploadShowBigImg($event)
                    },
                    change: _vm.handleUploadCoverImg
                  }
                },
                [
                  _vm.coverImgList.length < 1
                    ? _c(
                        "div",
                        [
                          _c("a-icon", { attrs: { type: "plus" } }),
                          _c("div", { staticClass: "ant-upload-text" }, [
                            _vm._v("上传封面图")
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ]
              )
            ],
            1
          ),
          _c("a-form-item", { attrs: { label: "上传正/反面图" } }, [
            _c(
              "div",
              { staticClass: "flex-start-center sp-upload-box" },
              [
                _c(
                  "a-upload",
                  {
                    key: "upload-front",
                    attrs: {
                      action: _vm.ip + "/file/upload",
                      "list-type": "picture-card",
                      "file-list": _vm.frontImgList
                    },
                    on: {
                      preview: function($event) {
                        return _vm.handleUploadShowBigImg($event)
                      },
                      change: _vm.handleUploadFrontImg
                    }
                  },
                  [
                    _vm.frontImgList.length < 1
                      ? _c(
                          "div",
                          [
                            _c("a-icon", { attrs: { type: "plus" } }),
                            _c("div", { staticClass: "ant-upload-text" }, [
                              _vm._v("上传正面图")
                            ])
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                ),
                _c(
                  "a-upload",
                  {
                    key: "upload-back",
                    attrs: {
                      action: _vm.ip + "/file/upload",
                      "list-type": "picture-card",
                      "file-list": _vm.backImgList
                    },
                    on: {
                      preview: function($event) {
                        return _vm.handleUploadShowBigImg($event)
                      },
                      change: _vm.handleUploadBackImg
                    }
                  },
                  [
                    _vm.backImgList.length < 1
                      ? _c(
                          "div",
                          [
                            _c("a-icon", { attrs: { type: "plus" } }),
                            _c("div", { staticClass: "ant-upload-text" }, [
                              _vm._v("上传反面图")
                            ])
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                )
              ],
              1
            )
          ]),
          _c(
            "a-form-item",
            { attrs: { label: "百科排序" } },
            [
              _c("a-input", {
                staticClass: "w-200",
                attrs: {
                  allowClear: "",
                  type: "number",
                  placeholder: "百科排序"
                },
                model: {
                  value: _vm.params.sort,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "sort", $$v)
                  },
                  expression: "params.sort"
                }
              })
            ],
            1
          ),
          _vm.isEditor
            ? _c(
                "a-form-item",
                { attrs: { label: "关联币种" } },
                _vm._l(_vm.selectedCoinList, function(item) {
                  return _c(
                    "a-tag",
                    {
                      key: item.coinId,
                      attrs: { color: "blue", closable: "" },
                      on: {
                        close: function($event) {
                          return _vm.handleDeleteTag($event, item)
                        }
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(item.coinName + "--" + item.coinCategoryName)
                      )
                    ]
                  )
                }),
                1
              )
            : _vm._e(),
          _c(
            "a-form-item",
            { attrs: { label: "朝代" } },
            [
              _c(
                "a-select",
                {
                  attrs: {
                    "filter-option": _vm.untils.filterOption,
                    showSearch: "",
                    allowClear: "",
                    placeholder: "请选择"
                  },
                  on: { search: _vm.handleSearchDynastyByName },
                  model: {
                    value: _vm.params.dynastyId,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "dynastyId", $$v)
                    },
                    expression: "params.dynastyId"
                  }
                },
                _vm._l(_vm.dynastyList, function(item, index) {
                  return _c(
                    "a-select-option",
                    { key: index, attrs: { value: item.id } },
                    [_vm._v(_vm._s(item.dynastyName))]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "关联大币种" } },
            [
              _c(
                "a-select",
                {
                  attrs: {
                    "filter-option": _vm.untils.filterOption,
                    showSearch: "",
                    mode: "multiple",
                    placeholder: "请选择"
                  },
                  on: { search: _vm.coinSearch },
                  model: {
                    value: _vm.coinIds,
                    callback: function($$v) {
                      _vm.coinIds = $$v
                    },
                    expression: "coinIds"
                  }
                },
                _vm._l(_vm.bigCoinList, function(item, index) {
                  return _c(
                    "a-select-option",
                    { key: index, attrs: { value: item.id } },
                    [
                      _vm._v(
                        _vm._s(
                          item.coinKindName +
                            (item.coinCategory ? "---" + item.coinCategory : "")
                        )
                      )
                    ]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "是否直接可使用" } },
            [
              _c(
                "a-radio-group",
                {
                  attrs: { name: "radioGroup", "default-value": 0 },
                  model: {
                    value: _vm.params.status,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "status", $$v)
                    },
                    expression: "params.status"
                  }
                },
                [
                  _c("a-radio", { attrs: { value: 0 } }, [_vm._v("不可用")]),
                  _c("a-radio", { attrs: { value: 1 } }, [_vm._v("可用")])
                ],
                1
              )
            ],
            1
          ),
          _vm.showPopup
            ? _c(
                "a-form-item",
                { attrs: { label: "百科内容说明" } },
                [
                  _c("a-input", {
                    staticClass: "w-100-w",
                    attrs: {
                      id: "inputKeywordTabDesc",
                      type: "textarea",
                      "auto-size": { minRows: 6, maxRows: 20 },
                      placeholder: "百科内容"
                    },
                    model: {
                      value: _vm.params.itemDesc,
                      callback: function($$v) {
                        _vm.$set(_vm.params, "itemDesc", $$v)
                      },
                      expression: "params.itemDesc"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }