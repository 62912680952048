var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-table", {
        attrs: {
          columns: _vm.tableList,
          pagination: _vm.pagination,
          "data-source": _vm.list,
          rowKey: function(record, index) {
            return index
          }
        },
        on: { change: _vm.changePage },
        scopedSlots: _vm._u([
          {
            key: "itemWikiTitleSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c("div", { staticClass: "font-weight-bold" }, [
                  _vm._v(_vm._s(row.title))
                ]),
                _c("div", { staticClass: "mt-5 font-weight-bold color-blue" }, [
                  _vm._v(
                    _vm._s(
                      row.dynastyName +
                        "--" +
                        row.wikiCategoryName +
                        "--" +
                        row.topicName
                    )
                  )
                ]),
                _c("div", { staticClass: "mt-5 font-weight-bold color-blue" }, [
                  _vm._v("【" + _vm._s(row.id) + "】")
                ])
              ])
            }
          },
          {
            key: "itemWikiCoverImgSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c("img", {
                  staticClass: "w-50 h-50",
                  attrs: { src: row.coverUrl, alt: "" },
                  on: {
                    click: function($event) {
                      return _vm.handleShowBigImg([row.coverUrl], 0)
                    }
                  }
                })
              ])
            }
          },
          {
            key: "itemWikiFrontAndBackImgSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c("img", {
                  staticClass: "w-50 h-50",
                  attrs: { src: row.frontUrl, alt: "" },
                  on: {
                    click: function($event) {
                      return _vm.handleShowBigImg(
                        [row.frontUrl, row.backUrl],
                        0
                      )
                    }
                  }
                }),
                _c("img", {
                  staticClass: "w-50 h-50",
                  attrs: { src: row.backUrl, alt: "" },
                  on: {
                    click: function($event) {
                      return _vm.handleShowBigImg(
                        [row.frontUrl, row.backUrl],
                        1
                      )
                    }
                  }
                })
              ])
            }
          },
          {
            key: "itemWikiAssociationCoinSlot",
            fn: function(row) {
              return _c(
                "div",
                {},
                _vm._l(row.coinList, function(item) {
                  return _c(
                    "a-tag",
                    {
                      key: item.coinId,
                      staticClass: "mb-5",
                      attrs: { color: "blue" }
                    },
                    [
                      _vm._v(
                        _vm._s(item.coinName + "--" + item.coinCategoryName)
                      )
                    ]
                  )
                }),
                1
              )
            }
          },
          {
            key: "itemStatusSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c(
                  "div",
                  {
                    staticClass: "font-weight-bold",
                    class: row.status ? "color-green" : "color-red"
                  },
                  [_vm._v(" " + _vm._s(row.status ? "可用" : "不可用") + " ")]
                )
              ])
            }
          },
          {
            key: "itemActionSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c(
                  "div",
                  [
                    _c(
                      "a-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.handleEditorCategoryDetail(row)
                          }
                        }
                      },
                      [_vm._v("编辑百科内容")]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "mt-5" },
                  [
                    _c(
                      "a-popconfirm",
                      {
                        attrs: { title: "确定删除？" },
                        on: {
                          confirm: function($event) {
                            return _vm.handleDeleteCategoryDetail(row)
                          }
                        }
                      },
                      [
                        _c("a-button", { attrs: { type: "danger" } }, [
                          _vm._v("删除")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            }
          }
        ])
      }),
      _c("EditorCategoryDetailPopup", {
        ref: "editorCategoryDetailPopupEl",
        attrs: { topCategoryTypeList: _vm.topCategoryTypeList },
        on: { success: _vm.handleSuccess }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }