<template>
  <div class="content">
    <div class="top">
      <a-input allowClear v-model="params.title" class="w-200 mb-5" placeholder='名称搜索'></a-input>
      <a-select
          allowClear
          showSearch
          :filter-option="untils.filterOption"
          class="ml-5 w-200 mb-5"
          v-model="params.categoryId"
          placeholder="一级大分类搜索"
          @change="handleChangeTopCategory($event)"
      >
        <a-select-option v-for="item of topCategoryTypeList" :key="item.id" :value="item.id">{{ item.categoryName }}</a-select-option>
      </a-select>
      <a-select
          allowClear
          showSearch
          :filter-option="untils.filterOption"
          class="ml-5 w-200 mb-5"
          v-model="params.topicId"
          placeholder="二级分类搜索"
      >
        <a-select-option v-for="item of leftCategoryTypeList" :key="item.id" :value="item.id">{{ item.topicName }}</a-select-option>
      </a-select>
      <a-select allowClear showSearch class="ml-5 w-200 mb-5" v-model="params.status" placeholder="是否可有">
        <a-select-option v-for="item of isCanUseStatusList" :key="item.value" :value="item.value">{{ item.name }}</a-select-option>
      </a-select>
      <a-button class="mb-5" type="primary" @click="search">搜索</a-button>
      <a-button class="mb-5" @click="handleAdd" icon="plus">添加数据</a-button>
    </div>
    <div class="table-content">
      <TableList
          :list="data"
          :pagination="pagination"
          :topCategoryTypeList="topCategoryTypeList"
          @changePage="handleChangePage"
          @success="getList"
      />
    </div>
    <!--  增加分类  -->
    <EditorCategoryDetailPopup
        ref="editorCategoryDetailPopupEl"
        :topCategoryTypeList="topCategoryTypeList"
        @success="getList"
    />
  </div>
</template>
<script>
import TableList from "@/views/cmsPage/currencyMannage/coinWikiCategoryDetailManage/_components/TableList/index.vue"
import {
  getCoinWikiTopBigCategoryListApi
} from "@/views/cmsPage/currencyMannage/coinWikiLeftCategoryManage/_apis"
import EditorCategoryDetailPopup
  from "@/views/cmsPage/currencyMannage/coinWikiCategoryDetailManage/_components/EditorCategoryDetailPopup/index.vue"
import {
  getCoinWikiCategoryDetailListApi
} from "@/views/cmsPage/currencyMannage/coinWikiCategoryDetailManage/_apis"
import _searchLeftCategoryListMixin
  from "@/views/cmsPage/currencyMannage/coinWikiCategoryDetailManage/_mixins/_searchLeftCategoryListMixin"
import untils from "@/untils"
import {isCanUseStatusList} from "@/views/cmsPage/currencyMannage/_data"

export default {
  mixins: [_searchLeftCategoryListMixin],
  components: {
    TableList, EditorCategoryDetailPopup
  },
  data() {
    return {
      untils,
      data: [],
      isCanUseStatusList,
      topCategoryTypeList: [],
      leftCategoryTypeList: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showTotal: (total) => `共${total}条`,
      },
      params: {
        pageNum: 1,
        pageSize: 10,
        title: undefined, // 标题查询
        categoryId: undefined, // 一级分类查询
        topicId: undefined, // 分类ID查询
        status: undefined, // 状态搜索
      },
    };
  },
  async created() {
    await this.getList()
    await this.getCoinCategoryList()
  },
  methods: {
    /** 添加数据 */
    handleAdd() {
      this.$refs.editorCategoryDetailPopupEl.show()
    },
    /** 翻页 */
    handleChangePage(page) {
      const { current, pageSize } = page;
      this.pagination.current = current;
      this.params.pageNum = current;
      this.params.pageSize = pageSize;
      this.getList();
    },
    /** 搜索 */
    search() {
      this.pagination.params = 1
      this.params.pageNum = 1
      this.getList();
    },
    /**  获取列表数据 */
    async getList() {
      this.$loading.show()
      const res = await getCoinWikiCategoryDetailListApi(this.params)
      this.$loading.hide()
      if (res.status !== '200') return;
      this.data = res.data.records;
      this.pagination.total = res.data.total;
    },
    /** 获取分类列表 */
    async getCoinCategoryList() {
      const res = await getCoinWikiTopBigCategoryListApi()
      if (res.status !== '200') return
      this.topCategoryTypeList = res.data
    },
    /** 切换顶部一级分类 */
    async handleChangeTopCategory(id) {
      if (id) {
        await this.handleSearchLeftCategory('', id)
      } else {
        this.leftCategoryTypeList = []
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  .table-content {
    flex: 1;
    overflow-y: auto;
  }
}

.top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
  button {
    margin-left: 10px;
  }
}
</style>
