import {getCoinWikiLeftCategoryListByNameApi} from "@/views/cmsPage/currencyMannage/coinWikiCategoryDetailManage/_apis"

export default {
    data() {
        return {}
    },
    methods: {
        /** 搜索左边列表分类 */
        async handleSearchLeftCategory(str, categoryId) {
            const res = await getCoinWikiLeftCategoryListByNameApi(str, categoryId)
            if (res.status !== '200') return
            this.leftCategoryTypeList = res.data.records
        }
    }
}