<template>
  <div>
    <a-table
        :columns="tableList"
        :pagination="pagination"
        :data-source="list"
        :rowKey="(record, index) => index"
        @change="changePage"
    >
<!--    标题  -->
      <div slot="itemWikiTitleSlot" slot-scope="row">
        <div class="font-weight-bold">{{ row.title }}</div>
        <div class="mt-5 font-weight-bold color-blue">{{ row.dynastyName + '--' + row.wikiCategoryName + '--' + row.topicName }}</div>
        <div class="mt-5 font-weight-bold color-blue">【{{ row.id }}】</div>
      </div>
<!--    封面图  -->
      <div slot="itemWikiCoverImgSlot" slot-scope="row">
        <img class="w-50 h-50" :src="row.coverUrl" alt="" @click="handleShowBigImg([row.coverUrl], 0)">
      </div>
<!--    正反图  -->
      <div slot="itemWikiFrontAndBackImgSlot" slot-scope="row">
        <img class="w-50 h-50" :src="row.frontUrl" alt="" @click="handleShowBigImg([row.frontUrl, row.backUrl], 0)">
        <img class="w-50 h-50" :src="row.backUrl" alt="" @click="handleShowBigImg([row.frontUrl, row.backUrl], 1)">
      </div>
<!--    关联大币种  -->
      <div slot="itemWikiAssociationCoinSlot" slot-scope="row">
        <a-tag
            color="blue"
            class="mb-5"
            v-for="item in row.coinList"
            :key="item.coinId">{{ item.coinName + '--' + item.coinCategoryName }}</a-tag>
      </div>
<!--    是否可用  -->
      <div slot="itemStatusSlot" slot-scope="row">
        <div class="font-weight-bold" :class="row.status ? 'color-green' : 'color-red'">
          {{ row.status ? '可用' : '不可用' }}
        </div>
      </div>
<!--    编辑  -->
      <div slot="itemActionSlot" slot-scope="row">
        <div><a-button type="primary" @click="handleEditorCategoryDetail(row)">编辑百科内容</a-button></div>
        <div class="mt-5">
          <a-popconfirm title="确定删除？" @confirm="handleDeleteCategoryDetail(row)">
            <a-button type="danger">删除</a-button>
          </a-popconfirm>
        </div>
      </div>
    </a-table>
<!--  编辑分类  -->
    <EditorCategoryDetailPopup
        ref="editorCategoryDetailPopupEl"
        :topCategoryTypeList="topCategoryTypeList"
        @success="handleSuccess"
    />
  </div>
</template>
<script>
import EditorCategoryDetailPopup
  from "@/views/cmsPage/currencyMannage/coinWikiCategoryDetailManage/_components/EditorCategoryDetailPopup/index.vue"
import {coinWikiCategoryDetailTableColumns} from "@/views/cmsPage/currencyMannage/coinWikiCategoryDetailManage/_data"
import _showBigImgMixin from "@/_mixins/_showBigImgMixin"
import {postDelWikiCategoryItemApi} from "@/views/cmsPage/currencyMannage/coinWikiCategoryDetailManage/_apis"

export default {
  components: {EditorCategoryDetailPopup},
  props: ['pagination', 'list', 'topCategoryTypeList'],
  emits: ['success', 'changePage'],
  mixins: [_showBigImgMixin],
  data() {
    return {
      tableList: coinWikiCategoryDetailTableColumns,
    }
  },
  methods: {
    /** 展示内容描述 */
    handleShowContentDesc(desc) {

    },
    /** 删除数据 */
    async handleDeleteCategoryDetail(row) {
      this.$loading.show()
      const res = await postDelWikiCategoryItemApi({id: row.id})
      this.$loading.hide()
      if (res.status !== '200') return
      this.$message.info('删除成功')
      this.handleSuccess()
    },
    /** 编辑分类 */
    handleEditorCategoryDetail(row) {
      this.$refs.editorCategoryDetailPopupEl.show(row)
    },
    /** 成功 */
    handleSuccess() {
      this.$emit('success')
    },
    /** 翻页 */
    changePage(page) {
      this.$emit("changePage", page);
    },
  }
}
</script>
<style lang="scss" scoped>
</style>