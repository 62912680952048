import instance from "@/axios"

/** 获取币种左边百科分类列表 */
export const getCoinWikiLeftCategoryListByNameApi = async (name, wikiCategoryId) => {
    return instance({
        url: '/dq_admin/coinWikiTopic/list',
        method: 'GET',
        params: {
            pageNum: 1,
            pageSize: 30,
            topicName: name,
            wikiCategoryId: wikiCategoryId,
        }
    })
}

/** 获取百科数据列表 */
export const getCoinWikiCategoryDetailListApi = async (params) => {
    return instance({
        url: '/dq_admin/coinWikiItem/list',
        method: 'GET',
        params: params
    })
}

/** 百科左边分类添加 */
export const postAddCoinWikiCategoryDetailApi = async (params) => {
    return instance({
        url: '/dq_admin/coinWikiItem/add',
        method: 'post',
        data: params
    })
}
/** 百科左边分类编辑 */
export const postEditorCoinWikiCategoryDetailApi = async (params) => {
    return instance({
        url: '/dq_admin/coinWikiItem/edit',
        method: 'post',
        data: params
    })
}
/** 百科左边分类删除 */
export const deleteCoinWikiCategoryDetailListApi = async (params) => {
    return instance({
        url: '/dq_admin/coinWikiItem/delById',
        method: 'GET',
        params: params
    })
}

/** 花钱小分类搜索列表 */
export const getHuaSmallCategoryListByDetailApi = async (topicId) => {
    return instance({
        url: '/dq_admin/coinWikiGroup/list',
        method: 'GET',
        params: {
            pageSize: 100,
            pageNum: 1,
            topicId: topicId
        }
    })
}
/** 删除百科数据 */
export const postDelWikiCategoryItemApi = async (itemId) => {
    return instance({
        url: '/dq_admin/coinWikiItem/delById',
        method: 'GET',
        params: {
            id: itemId,
        }
    })
}