import {returnAntUploadImgObj} from "@/untils/otherEvent"

export default {
    data() {
        return {}
    },
    methods: {
        /** 回显数据 */
        handleReturnShowEditorData(data) {
            if (data.coverUrl) this.coverImgList = returnAntUploadImgObj([data.coverUrl])
            if (data.frontUrl) this.frontImgList = returnAntUploadImgObj([data.frontUrl])
            if (data.backUrl) this.backImgList = returnAntUploadImgObj([data.backUrl])
        },
        /** 上传封面图 */
        handleUploadCoverImg({file, fileList}) {
            fileList.map((row) => {
                try {
                    if (row.response.data) {
                        row.url = row.response.data;
                    }
                } catch (e) {}
            });
            this.coverImgList = fileList
        },
        /** 上传正面图 */
        handleUploadFrontImg({file, fileList}) {
            fileList.map((row) => {
                try {
                    if (row.response.data) {
                        row.url = row.response.data;
                    }
                } catch (e) {}
            });
            this.frontImgList = fileList
        },
        /** 上传反面图 */
        handleUploadBackImg({file, fileList}) {
            fileList.map((row) => {
                try {
                    if (row.response.data) {
                        row.url = row.response.data;
                    }
                } catch (e) {}
            });
            this.backImgList = fileList
        },
    }
}