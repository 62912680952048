import instance from "@/axios"

/** 获取币种顶部百科大分类列表 */
export const getCoinWikiTopBigCategoryListApi = async () => {
    return instance({
        url: '/dq_admin/coinWikiCategory/list',
        method: 'GET',
        params: {
            pageNum: 1,
            pageSize: 50,
        }
    })
}

/** 获取币种左边百科分类列表 */
export const getCoinWikiLeftCategoryListApi = async (params) => {
    return instance({
        url: '/dq_admin/coinWikiTopic/list',
        method: 'GET',
        params: params
    })
}

/** 百科左边分类添加 */
export const postAddCoinWikiLeftCategoryApi = async (params) => {
    return instance({
        url: '/dq_admin/coinWikiTopic/add',
        method: 'post',
        data: params
    })
}
/** 百科左边分类编辑 */
export const postEditorCoinWikiLeftCategoryApi = async (params) => {
    return instance({
        url: '/dq_admin/coinWikiTopic/edit',
        method: 'post',
        data: params
    })
}
/** 百科左边分类删除 */
export const getDeleteCoinWikiLeftCategoryListApi = async (params) => {
    return instance({
        url: '/dq_admin/coinWikiTopic/delById',
        method: 'GET',
        params: params
    })
}

/** 获取花钱分类 */
export const getHuaSmallCategoryListApi = async (params) => {
    return instance({
        url: '/dq_admin/coinWikiGroup/list',
        method: 'GET',
        params: params
    })
}
/** 添加花钱小分类 */
export const postAddHuaSmallCategoryApi = async (params) => {
    return instance({
        url: '/dq_admin/coinWikiGroup/add',
        method: 'post',
        data: params
    })
}
/** 获取花钱分类 */
export const postEditHuaSmallCategoryApi = async (params) => {
    return instance({
        url: '/dq_admin/coinWikiGroup/edit',
        method: 'post',
        data: params
    })
}